import { useUserStore } from '@/stores/userStore';
import { useTokensStore } from '@/stores/tokensStore';
import PremiumUpsellModal from '@/components/premium/UpsellModal.vue';

export const usePremiumCheck = () => {
  const modal = useModal();
  const userStore = useUserStore();
  const tokensStore = useTokensStore();
  const reviewsStore = useReviewsStore();

  const isActivePremium = computed(
    () =>
      !userStore.user?.isCmUser ||
      (userStore.user?.isCmUser && userStore.user?.isCmPremium)
  );
  const tokensCount = computed(() => tokensStore.validTokens.length);

  const isExSampler = computed(() => {
    return (
      !isActivePremium.value &&
      tokensCount.value < 1 &&
      !reviewsStore?.pendingReviewsAmount
    );
  });

  const lostAccessMidCycle = computed(() => {
    return !isActivePremium.value && tokensCount.value > 0;
  });

  const openUpsellModal = (section: string) => {
    modal.open(PremiumUpsellModal, {
      section,
    });
  };

  return {
    isActivePremium: isActivePremium.value,
    isExSampler: isExSampler.value,
    lostAccessMidCycle,
    openUpsellModal,
  };
};
